export class Search {
	private search_container: HTMLElement | null;
	private input: HTMLInputElement | null;
	private buffer_container: HTMLDivElement;
	private search_result_container: HTMLElement | null;
	private search_filters_container: HTMLElement | null;
	private search_filters: NodeListOf<Element> | null;
	private filters: string[];
	private suggested_template_id: string;
	private endpoint: string;

	constructor(
		search_container: HTMLElement | null = document.getElementById( 'js-search' ),
		input: HTMLInputElement | null = document.getElementById( 'js-search-input' ) as HTMLInputElement,
		search_result_container: HTMLElement | null = document.getElementById( 'js-search-results-receptacle' ),
		filters: string[] = ['events', 'blog', 'people'],
		suggested_template_id: string = 'suggested-template',
		endpoint: string = 'search/'
	) {
		this.search_container = search_container;
		this.input = input;
		this.buffer_container = document.createElement( 'div' );
		this.search_result_container = search_result_container;
		this.search_filters_container = document.getElementById( 'js-search-filters' );
		this.search_filters = document.querySelectorAll( '.search-filter__button' );
		this.filters = filters;
		this.suggested_template_id = suggested_template_id;
		this.endpoint = endpoint;

		const delay = ( () => {
			let timer: number;
			return ( callback: () => void, ms: number ) => {
				clearTimeout( timer );
				timer = window.setTimeout( callback, ms );
			};
		} )();

		if ( this.input ) {
			this.input.addEventListener( 'keyup', () => {
				delay( () => {
					if ( this.input && this.input.value.length > 1 ) {
						const filter = this.search_filters_container?.querySelector<HTMLElement>( '.search-filter__button--active' )?.dataset.searchFilterHandle || '';
						this.search_filters_container.style.opacity = '1';
						this.makeRequest( this.endpoint, this.input.value, this.search_result_container, filter );
						this.search_container?.classList.add( 'loading' );
					} else {
						if ( this.search_result_container ) this.search_result_container.innerHTML = '';
					}
				}, 1000 );
			} );
		}

		if ( this.search_filters_container && this.search_filters ) {
			this.search_filters.forEach( ( selected_filter ) => {
				const filter_element = selected_filter as HTMLElement;
				filter_element.addEventListener( 'click', () => {
					console.log('la');

					this.searchFilter( filter_element );
				});
				filter_element.addEventListener( 'keydown', ( event: KeyboardEvent ) => {
					if ( event.key === 'Enter' ) {
						this.searchFilter( filter_element );
					}
				});
			});
		}
	}

	private searchFilter( selected_filter: HTMLElement ): void {
		this.search_filters?.forEach( ( search_filter ) => {
			search_filter.classList.remove( 'search-filter__button--active' );
		});
		selected_filter.classList.add( 'search-filter__button--active' );

		this.makeRequest( this.endpoint, this.input?.value || '', this.search_result_container, selected_filter.getAttribute( 'data-search-filter-handle' ) || '' );
		this.search_container?.classList.add( 'loading' );
	}

	private renderTemplate( id: string, variables: Record<string, string> ): string {
		const template = document.getElementById( id )?.textContent || '';
		if ( !template ) return '';

		if ( id === 'suggested-template' && variables.section === 'People' ) {
			const people_template = document.getElementById( 'suggested-people-template' )?.textContent || '';
			return people_template.replace( /%([^% ]+)%/gi, ( _, tag_name ) => variables[tag_name] || '' );
		} else {
			return template.replace( /%([^% ]+)%/gi, ( _, tag_name ) => variables[tag_name] || '' );
		}
	}

	private async makeRequest( endpoint: string, value: string, search_result_container: HTMLElement | null, filter: string ): Promise<void> {
		const base_url = document.getElementById( 'js-base-url' )?.getAttribute( 'data-url' ) || '';
		const url = `${base_url}${endpoint}${value.replace( / /g, '_' )}.json`;

		try {
			const response = await fetch( url );
			if ( !response.ok ) throw new Error( 'Network response was not ok' );
			const jsonData = await response.json();
			const data = jsonData.data || [];
			let html = '';
			this.search_container?.classList.remove( 'loading' );
			if ( search_result_container ) search_result_container.innerHTML = '';

			if ( !data.length ) {
				search_result_container!.innerHTML = '<p class="">No results.</p>';
				return;
			}

			data.forEach( ( result: any ) => {
				if ( !result.title ) return;
				if ( !result.section ) return;
				if ( filter === 'other' && this.filters.includes( result.section.handle ) ) return;
				if ( filter && filter !== 'other' && filter !== result.section.handle ) return;

				html += this.renderTemplate( this.suggested_template_id, {
					id: result.id,
					title: result.title,
					url: result.url,
					section: result.section.name,
					image: result.image,
					image_alt: result.image_alt,
				});
				this.buffer_container.innerHTML = html;
			});

			if ( !this.buffer_container.hasChildNodes() ) {
				search_result_container!.innerHTML = '<p class="">No results.</p>';
				return;
			}

			while ( this.buffer_container.hasChildNodes() ) {
				search_result_container!.appendChild( this.buffer_container.firstChild! );
			}
		} catch ( error ) {
			console.error( 'Fetch error:', error );
		}
	}
}
