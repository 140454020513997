import { EmblaCarouselType } from 'embla-carousel';

export const addTogglePrevNextBtnsActive = (
	embla_api: EmblaCarouselType,
	prev_btn: HTMLElement,
	next_btn: HTMLElement,
): ( () => void ) => {
	const toggle_prev_next_btn_state = (): void => {
		if ( embla_api.canScrollPrev() ) {
			prev_btn.removeAttribute( 'disabled' );
		} else {
			prev_btn.setAttribute( 'disabled', 'disabled' );
		}
		if ( embla_api.canScrollNext() ) {
			next_btn.removeAttribute( 'disabled' );
		} else {
			next_btn.setAttribute( 'disabled', 'disabled' );
		}
	}

	embla_api
		.on( 'select', toggle_prev_next_btn_state )
		.on( 'init', toggle_prev_next_btn_state)
		.on( 'reInit', toggle_prev_next_btn_state );

	return (): void => {
		prev_btn.removeAttribute( 'disabled' );
	}
};

export const addPrevNextBtnsClickHandlers = (
	embla_api: EmblaCarouselType,
	prev_btn: HTMLElement,
	next_btn: HTMLElement,
): ( () => void ) => {
	const scroll_prev = (): void => {
		embla_api.scrollPrev();
	};
	const scroll_next = (): void => {
		embla_api.scrollNext();
	};

	prev_btn.addEventListener( 'click', scroll_prev, false );
	next_btn.addEventListener( 'click', scroll_next, false );

	const remove_toggle_prev_next_btns_active = addTogglePrevNextBtnsActive( embla_api, prev_btn, next_btn );
	return (): void => {
		remove_toggle_prev_next_btns_active();
		prev_btn.removeEventListener( 'click', scroll_prev, false );
		next_btn.removeEventListener( 'click', scroll_next, false );
	}
};