
export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function formatBytes( bytes, decimals = 2 ) {
	if ( !+bytes ) {
		return '0 Bytes';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB'];
	const i = Math.floor( Math.log( bytes ) / Math.log( k ) );

	return `${parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( dm ) )} ${sizes[i]}`;
}

export function setupShareLinks( selector = '.social-share' ) {
	const btns = document.querySelectorAll( selector );
	if ( !btns[0] ) {
		return;
	}

	for ( let i = 0; i < btns.length; i++ ) {
		btns[i].addEventListener( 'click', handleSocialShare( btns[i] ) );
	}

	function handleSocialShare( btn ) {
		return ( e ) => {
			e.preventDefault();
			popUpWindow( btn.href );
		};
	}
	function popUpWindow( url ) {
		const popup_width = 550;
		const popup_height = 420;
		const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
		const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;
		const new_window = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );
		if ( window.focus ) {
			new_window.focus();
		}
		return false;
	}
}
export function renderTemplate( template, variables ) {
	return template.replace( /%([^% ]+)%/gi, ( match, tag_name ) => {
		return variables[tag_name] || '';
	} );
}
