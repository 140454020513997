
import { breakpoints } from '../variables.json';

import 'focus-visible';
// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';
import { Search } from './vendor/search';
import { formatBytes, renderTemplate, getWindowWidth } from './helpers.js';
import EmblaCarousel, { EmblaOptionsType } from 'embla-carousel';
import { addPrevNextBtnsClickHandlers } from './vendor/embla';

export * from './CookieConsentManager.js';
// export * from './vue/app.js';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare const pca: any;

( () => {
	// Nav toggle
	const menu_toggle = document.querySelector( '.header__menu' ) as HTMLButtonElement;
	if ( !menu_toggle ) {
		return;
	}
	window.addEventListener( 'click', ( e ) => {
		const { target } = e;
		if ( target === menu_toggle ) {
			toggleNav();
		}
	} );

	function toggleNav() {
		if ( document.body.classList.contains( 'menu-open' ) ) {
			document.body.classList.remove( 'menu-open' );
			menu_toggle.setAttribute( 'aria-expanded', 'false' );
			return;
		}
		document.body.classList.add( 'menu-open' );
		menu_toggle.setAttribute( 'aria-expanded', 'true' );
	}
} )();

( () => {
	// File inputs
	const file_input_elements = document.querySelectorAll( '.form__file' );

	file_input_elements.forEach( element => {
		const input = element.querySelector( 'input[type="file"]' ) as HTMLInputElement;
		if ( !input ) {
			return;
		}

		const existing_image_thumb = element.querySelector( '.form__file-image' );
		const file_name_label = element.querySelector( '.form__file-name' );
		const remove_file = file_name_label.querySelector( '.form__remove-file' ) as HTMLButtonElement;
		const name_tag = file_name_label.querySelector( 'p' );
		const size_tag = file_name_label.querySelector( 'span' );

		input.addEventListener( 'change', () => {
			if ( !input.files.length ) {
				element.classList.remove( 'form__file--file-added' );

				if ( existing_image_thumb ) {
					existing_image_thumb.classList.remove( 'form__file-image--hidden' );
				}
				return;
			}

			if ( input.files[0].size > 5242880 ) {
				alert( 'File too big' );
				input.value = null;
				return;
			}

			const file = input.files[0];

			name_tag.innerHTML = file.name;
			size_tag.innerHTML = formatBytes( file.size, 0 );
			element.classList.add( 'form__file--file-added' );

			if ( existing_image_thumb ) {
				existing_image_thumb.classList.add( 'form__file-image--hidden' );
			}
		} );

		remove_file.addEventListener( 'click', () => {
			element.classList.remove( 'form__file--file-added' );
			input.value = null;

			if ( existing_image_thumb ) {
				existing_image_thumb.classList.remove( 'form__file-image--hidden' );
			}
		} );
	} );
} )();

( () => {
	// Video posters
	const videos = document.querySelectorAll( '.video-embed' );

	if ( !videos ) {
		return;
	}

	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', '1280' );
		iframe.setAttribute( 'height', '720' );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', '0' );
		iframe.setAttribute( 'allowfullscreen', 'true' );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		container.classList.remove( 'unplayed' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	window.addEventListener( 'click', ( event ) => {
		const video = isAVideo( event.target );
		if ( !video ) {
			return;
		}
		event.preventDefault();
		buildVideoPlayer( video );
	} );

	function isAVideo( element ) {
		let node = element;
		while ( node !== null && node !== undefined && node.classList !== undefined ) {
			if ( node.classList.contains( 'video-embed' ) ) {
				return node;
			}
			node = node.parentNode;
		}
		return false;
	}

} )();

( () => {
	// Copy site link
	if ( window.navigator ) {
		window.addEventListener( 'click', ( event ) => {
			const { target } = event;
			// eslint-disable-next-line snakecasejs/snakecasejs
			if ( target instanceof( HTMLElement ) ) {
				if ( target.classList.contains( 'js-copy-link-button' ) ) {
					const input = <HTMLInputElement>target.previousElementSibling;
					window.navigator.clipboard.writeText( input.value );
					target.insertAdjacentHTML( 'afterend', '<div class="copy-link__confirm">Link copied!</div>' );
					setTimeout( () => {
						document.querySelectorAll( '.copy-link__confirm' ).forEach( el => el.remove() );
					}, 3000 );
				}
			}
		} );
	}
} )();

( () => {
	// Loqate - Address Forms
	const address_search = document.querySelector( '#address-form-search' ) as HTMLInputElement;
	if ( !address_search ) {
		return;
	}

	const loqate_key = ( window as any ).loqateKey;  // eslint-disable-line @typescript-eslint/no-explicit-any

	const address_fields = [
		{ element: 'address-form-search', field: '', mode: pca.fieldMode.SEARCH }, // eslint-disable-line no-undef
		{ element: 'fields[address_line_1]', field: 'Line1', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'fields[address_line_2]', field: 'Line2', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'fields[city]', field: 'City', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'fields[county]', field: 'Province', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'fields[postcode]', field: 'PostalCode', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'fields[country]', field: 'CountryName', mode: pca.fieldMode.COUNTRY } // eslint-disable-line no-undef
	];

	const loqate_control = new pca.Address( address_fields, { key: loqate_key } ); // eslint-disable-line no-undef, no-unused-vars

	loqate_control.listen( 'populate', () => {
		address_search.value = '';
		address_search.blur();
	} );
} )();

( () => {
	// Filters – including Loqate and Geolocation
	const filter = document.querySelector( '.filter' ) as HTMLDivElement;
	if ( !filter ) {
		return;
	}

	const filter_toggle = filter.querySelector( '.filter__toggle button' ) as HTMLButtonElement;
	const filter_form = filter.querySelector( '#js-filters' );
	const results_container = document.getElementById( 'js-filter-results' );
	if ( !filter_form || !results_container ) {
		return;
	}

	if ( filter_toggle ) {
		filter_toggle.onclick = () => {
			filter.classList.toggle( 'filter--show' );
		};
	}

	// Dashboard events search filter
	const event_search = document.querySelector( '#event-search' ) as HTMLInputElement;
	const event_search_filter_input = document.querySelector( '#event-search-filter' ) as HTMLInputElement;

	// Location filter elements
	const address_search = document.querySelector( '#event-address-search' ) as HTMLInputElement;
	const address_filter_input = document.querySelector( '#address-filter' ) as HTMLInputElement;
	const my_location_button = document.querySelector( '#location-prompt' ) as HTMLButtonElement;
	const coordinates_filter_input = document.querySelector( '#coordinates-filter' ) as HTMLInputElement;

	const pagination_container = document.getElementById( 'pagination' );

	window.addEventListener( 'change', ( e ) => {
		const { target } = e;
		// eslint-disable-next-line snakecasejs/snakecasejs
		if ( target instanceof( HTMLInputElement ) ) {

			if ( target.parentElement.classList.contains( 'filter__input' ) ) {
				const form = target.closest( '#js-filters' ) as HTMLFormElement;
				filterFormQuery( form );
			}
		}
	} );

	window.addEventListener( 'click', ( e ) => {
		const { target } = e;
		// eslint-disable-next-line snakecasejs/snakecasejs
		if ( target instanceof( HTMLElement ) ) {
			if ( target.classList.contains( 'filter__clear-button' ) ) {
				const form = target.closest( '#js-filters' ) as HTMLFormElement;
				if ( form && form.tagName === 'FORM' ) {
					if ( target.id === 'clear-all-filters' ) {
						// Clear all filters
						form.querySelectorAll( 'input' ).forEach( field => {
							if ( field.type === 'checkbox' ) {
								field.checked = false;
							}
						} );
						if ( event_search && event_search_filter_input ) {
							event_search.value = null;
							event_search_filter_input.value = null;
						}
						if ( address_search && address_filter_input ) {
							address_search.value = null;
							address_filter_input.value = null;
						}
						if ( coordinates_filter_input ) {
							coordinates_filter_input.value = null;
						}
					} else {
						// Clear individual filter
						const filter_name = target.dataset.filterName;
						form.querySelectorAll( 'input' ).forEach( field => {
							if ( field.type === 'checkbox' ) {
								const filter_value = target.dataset.filterValue;
								if ( field.name === filter_name && field.value === filter_value ) {
									field.checked = false;
								}
							}
							if ( field.type === 'text' || field.type === 'hidden' ) {
								if ( field.name === filter_name ) {
									field.value = null;
								}
							}
						} );
					}
				}
				requestAnimationFrame( () => filterFormQuery( form ) );
			}
		}
	} );

	async function filterFormQuery( form: HTMLFormElement ) {
		const all_params = new URLSearchParams( window.location.search );
		const form_data = new FormData( form );
		/* eslint-disable  @typescript-eslint/no-explicit-any */
		// TS won't allow FormData to be passed to URLSearchParams because
		// forms might contain a file, but we know the FormData for filters
		// will only ever be made up out of checkboxes and text inputs
		// so we add 'as any' so it won't throw an error here.
		const search_params = new URLSearchParams( form_data as any );
		// Remove existing keys that are no longer present in the form data
		for ( const key of [...all_params.keys()] ) {
			if ( !search_params.has( key ) ) {
				all_params.delete( key );
			}
		}
		// Clear previous values for each key and then add the new ones
		for ( const key of search_params.keys() ) {
			all_params.delete( key );
			search_params.getAll( key ).forEach( ( value ) => {
				all_params.append( key, value );
			} );
		}
		// Final cleanup: remove empty keys
		for ( const key of [...all_params.keys()] ) {
			if ( !all_params.getAll( key ).length || !all_params.getAll( key ).filter( value => value ).length ) {
				all_params.delete( key );
			}
		}

		let url = `${window.location.pathname}` as string;
		const query_string = all_params.toString();
		if ( query_string.length ) {
			url += `?${query_string}`;
		}
		// Update URL without reloading
		window.history.pushState( null, null, decodeURI( url ) );
		// Fetch filtered results asynchronously
		await sendFilteredContent( url );
	}

	async function sendFilteredContent( url: string ) {
		try {
			const response = await fetch( url, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest'
				}
			} );

			if ( !response.ok ) {
				throw new Error( `HTTP error! Status: ${response.status}` );
			}

			const response_text = await response.text();
			updateFilteredContent( response_text );
		} catch ( error ) {
			// eslint-disable-next-line
			console.error( 'Error fetching filtered content:', error );
			results_container.innerHTML = '<p>Sorry, unable to load results.</p>';
		}
	}

	function updateFilteredContent( responseText: string ) {
		results_container.innerHTML = '';
		if ( pagination_container ) {
			pagination_container.innerHTML = '';
		}

		const parser = new DOMParser();
		const new_results_container = parser.parseFromString( responseText, 'text/html' );

		const new_results_content = new_results_container.getElementById( 'js-filter-results' );
		const new_pagination_content = new_results_container.getElementById( 'pagination' );
		const active_filters = new_results_container.querySelector( '#active-filters' );
		const existing_active_filters = filter_form.querySelector( '#active-filters' );

		if ( new_results_content ) {
			results_container.innerHTML = new_results_content.innerHTML;
			if ( pagination_container && new_pagination_content ) {
				pagination_container.innerHTML = new_pagination_content.innerHTML;
			}
			if ( active_filters ) {
				if ( existing_active_filters ) {
					filter_form.replaceChild( active_filters, existing_active_filters );
				} else {
					filter_form.appendChild( active_filters );
				}
			} else if ( existing_active_filters ) {
				existing_active_filters.remove();
			}

		} else {
			results_container.innerHTML = '<p>Sorry, there are no results for this filter.</p>';
		}
	}

	// Dashboard event search
	if ( event_search && event_search_filter_input ) {
		let timeout = null;

		event_search.addEventListener( 'input', () => {
			clearTimeout( timeout );
			timeout = setTimeout( () => {
				event_search_filter_input.value = event_search.value;
				const form = event_search_filter_input.closest( '#js-filters' ) as HTMLFormElement;
				filterFormQuery( form );
			}, 1000 );
		} );
	}

	// Event filters – Loqate
	if ( address_search && address_filter_input ) {
		const loqate_key = ( window as any ).loqateKey;
		const fields = [ { element: 'event-address-search', field: '', mode: pca.fieldMode.SEARCH } ]; // eslint-disable-line no-undef

		const loqate_control = new pca.Address( fields, { key: loqate_key } ); // eslint-disable-line no-undef, no-unused-vars

		loqate_control.listen( 'populate', async( address: any ) => {
			const full_address = [
				address.Line1,
				address.Line2,
				address.City,
				address.Province,
				address.PostalCode,
				address.CountryName
			].filter( Boolean ).join( ', ' );

			// Clear coordinates input so we don't have two clashing locations
			coordinates_filter_input.value = '';

			address_filter_input.value = full_address;
			address_search.value = full_address;
			address_search.blur();

			const form = address_filter_input.closest( '#js-filters' ) as HTMLFormElement;
			filterFormQuery( form );
		} );
	}

	// Event filters – My location
	if ( my_location_button ) {
		my_location_button.onclick = async() => {
			navigator.permissions.query(
				{ name: 'geolocation' }
			).then( ( permissions ) => {
				if ( permissions.state === 'granted' || permissions.state === 'prompt' ) {
					handleLocationAccepted();
				}
			} );
		};
	}

	function handleLocationAccepted() {
		my_location_button.innerHTML = 'Locating...';
		navigator.geolocation.getCurrentPosition( ( position ) => {
			const latlng = position.coords.latitude + ',' + position.coords.longitude;

			// Clear address inputs so we don't have two clashing locations
			address_filter_input.value = '';
			address_search.value = '';

			coordinates_filter_input.value = latlng;

			const form = address_filter_input.closest( '#js-filters' ) as HTMLFormElement;
			filterFormQuery( form );

			my_location_button.innerHTML = 'Use your location';
		}, () => {
			my_location_button.innerHTML = 'Location not found!';
			setTimeout( () => {
				my_location_button.innerHTML = 'Use your location';
			}, 2000 );
		}, { timeout: 5000 } );
	}
} )();

( () => {
	// Flash messages
	// eslint-disable-next-line no-undef
	const flash_messages = document.querySelectorAll( '.flash' ) as NodeListOf<HTMLDivElement>;

	flash_messages.forEach( flash => {
		const close = flash.querySelector( '.flash__close' ) as HTMLButtonElement;
		if ( !close ) {
			return;
		}

		close.onclick = () => {
			flash.style.display = 'none';
		};
	} );
} )();

( () => {
	// Event date input groups
	// eslint-disable-next-line no-undef
	const event_dates_blocks = document.querySelectorAll( '.event-dates-block' ) as NodeListOf<HTMLDivElement>;

	event_dates_blocks.forEach( event_dates_block => {

		const add_event_date_button = document.querySelector( '#add-another-date' ) as HTMLButtonElement;
		const form_group_template = document.querySelector( '#event-date-form-group-template' );
		if ( !event_dates_block || !add_event_date_button || !form_group_template ) {
			return;
		}

		add_event_date_button.onclick = () => {
			const form_groups = event_dates_block.children;
			const new_index = form_groups.length + 1;

			const new_group = document.createElement( 'div' );
			new_group.classList.add( 'event-date-form-group' );
			new_group.innerHTML = renderTemplate( form_group_template.textContent, { index: new_index } );

			event_dates_block.appendChild( new_group );
		};

		event_dates_block.onclick = e => {
			const target = e.target as HTMLButtonElement;
			if ( !target.classList.contains( 'remove-event-date' ) ) {
				return;
			}
			const form_group = target.parentElement.parentElement;
			form_group.remove();

			reIndexEventDates();
		};

		function reIndexEventDates() {
			const event_date_form_groups = event_dates_block.querySelectorAll( '.event-date-form-group' );

			event_date_form_groups.forEach( ( group, index ) => {
				const date_form_set = group.querySelector( '.date' ) as HTMLDivElement;
				const start_time_form_set = group.querySelector( '.start-time' ) as HTMLDivElement;
				const end_time_form_set = group.querySelector( '.end-time' ) as HTMLDivElement;

				[date_form_set, start_time_form_set, end_time_form_set].forEach( ( form_set, i ) => {
					const label = form_set.querySelector( '.form__label' ) as HTMLLabelElement;
					const input = form_set.querySelector( 'input' ) as HTMLInputElement;

					const type = i === 0 ? 'date' : i === 1 ? 'start_time' : 'end_time';
					const attribute = `fields[event_dates][blocks][new:${index + 1}][${type}]`;

					label.htmlFor = attribute;
					input.id = attribute;
					input.name = attribute;
				} );
			} );
		}
	} );
} )();

( () => {
	// Ticket price input groups
	const event_ticket_price_blocks = document.querySelectorAll( '.event-ticket-price-block' );

	event_ticket_price_blocks.forEach( ticket_price_block => {
		// eslint-disable-next-line no-undef
		const entry_cost_radios = ticket_price_block.querySelectorAll( 'input[name="fields[entry_cost]"]' ) as NodeListOf<HTMLInputElement>;
		const price_field_set = ticket_price_block.querySelector( '.event-ticket-price-field-set' ) as HTMLDivElement;
		if ( !entry_cost_radios.length || !price_field_set ) {
			return;
		}

		const price_input = price_field_set.querySelector( 'input[name="fields[ticket_price]"]' ) as HTMLInputElement;

		entry_cost_radios.forEach( input => {
			input.onchange = e => {
				const target = e.target as HTMLInputElement;


				if ( target.value === 'paid' ) {
					price_field_set.classList.remove( 'removed' );
				}
				if ( target.value === 'free' ) {
					price_field_set.classList.add( 'removed' );
					price_input.value = '';
				}
			};
		} );
	} );
} )();

( () => {
	// Attendance ticket groups
	const blocks  = document.querySelectorAll( '.attendance-block' );

	if ( !blocks ) {
		return;
	}

	blocks.forEach( block => {
		// eslint-disable-next-line no-undef
		const attendance_radios = block.querySelectorAll( 'input[name="fields[attendance]"]' ) as NodeListOf<HTMLInputElement>;
		const attendance_block_set_inperson = block.querySelector( '.attendance-location-fieldset--inperson' );
		const attendance_block_set_online = block.querySelector( '.attendance-location-fieldset--online' );
		console.log(attendance_radios);
		attendance_radios.forEach( input => {
			input.onchange = e => {
				const target = e.target as HTMLInputElement;


				if ( target.value === 'in_person' ) {
					attendance_block_set_online.classList.add( 'removed' );
					attendance_block_set_inperson.classList.remove( 'removed' );
				}
				if ( target.value === 'in_person_and_online' ) {
					attendance_block_set_inperson.classList.remove( 'removed' );
					attendance_block_set_online.classList.remove( 'removed' );
				}
				if ( target.value === 'online_only' ) {
					attendance_block_set_online.classList.remove( 'removed' );
					attendance_block_set_inperson.classList.add( 'removed' );
				}
			}
		} );
	} );
} )();

( () => {
	// Ticket booking link input groups
	const event_booking_link_blocks = document.querySelectorAll( '.event-booking-link-block' );

	event_booking_link_blocks.forEach( booking_link_block => {
		// eslint-disable-next-line no-undef
		const booking_required_radios = booking_link_block.querySelectorAll( 'input[name="fields[booking_required]"]' ) as NodeListOf<HTMLInputElement>;
		const booking_link_fields = booking_link_block.querySelector( '.event-booking-link-toggleable-fields' ) as HTMLDivElement;
		if ( !booking_required_radios.length || !booking_link_fields  ) {
			return;
		}

		const booking_link_input = booking_link_fields.querySelector( 'input[name="fields[ticket_booking_link]"]' ) as HTMLInputElement;

		booking_required_radios.forEach( input => {
			input.onchange = e => {
				const target = e.target as HTMLInputElement;

				if ( target.value === 'yes' ) {
					booking_link_fields.classList.remove( 'removed' );
				}
				if ( target.value === 'no' ) {
					booking_link_fields.classList.add( 'removed' );
					booking_link_input.value = '';
				}
			};
		} );
	} );
} )();

( () => {
	// Accordion
	const accordion_groups = document.querySelectorAll( '.js-accordion-group' );
	if ( !accordion_groups ) {
		return;
	}

	accordion_groups.forEach( group => {
		const accordion_items = group.querySelectorAll( '.js-accordion' );
		for ( let i = 0; i < accordion_items.length; i += 1 ) {
			const accordion_button = accordion_items[i].querySelector( '.js-accordion-toggle' );

			accordion_button.addEventListener( 'click', function() {
				if ( this.classList.contains( 'active' ) ) {
					closeAllPanels();
					return;
				}
				closeAllPanels();
				this.classList.add( 'active' );
				this.setAttribute( 'aria-expanded', 'true' );

				const panel = this.nextElementSibling;
				panel.style.opacity = 1;
				panel.style.maxHeight = panel.scrollHeight + 'px';
				panel.style.visibility = 'visible';
			} );
		}

		window.addEventListener( 'resize', () => {
			for ( let i = 0; i < accordion_items.length; i += 1 ) {
				const breakpoint = accordion_items[i].getAttribute( 'data-breakpoint' );
				const panel = accordion_items[i].querySelector( '.js-accordion-content' ) as HTMLElement;

				if ( breakpoint && getWindowWidth() > breakpoints[breakpoint].width ) {
					panel.style.visibility = 'visible';
					panel.style.opacity = '1';
					panel.style.maxHeight = panel.scrollHeight + 'px';
				} else if ( breakpoint && getWindowWidth() < breakpoints[breakpoint].width ) {
					panel.style.visibility = 'hidden';
					panel.style.opacity = '0';
					panel.style.maxHeight = null;
				}

			}
		} );

		function closeAllPanels() {
			for ( let i = 0; i < accordion_items.length; i += 1 ) {
				const button = accordion_items[i].querySelector( '.js-accordion-toggle' );
				const panel = accordion_items[i].querySelector( '.js-accordion-content' ) as HTMLElement;

				panel.style.maxHeight = null;
				panel.style.visibility = 'hidden';
				panel.style.opacity = '0';
				button.classList.remove( 'active' );
				button.setAttribute( 'aria-expanded', 'false' );
			}
		}
	} );
} )();

( () => {
	// Dynamically generate jump-to
	const jump_to_container = document.getElementById( 'jump-to' ) as HTMLElement;
	if ( !jump_to_container ) {
		return;
	}

	const ul = jump_to_container.querySelector( 'ul' );
	const rich_text_containers = document.querySelectorAll( '.rich-text' );
	let has_links = false;
	rich_text_containers.forEach( container => {
		const all_h2 = container.getElementsByTagName( 'h2' );
		for ( let i = 0; i < all_h2.length; i++ ) {
			const h2 = all_h2[i];
			if ( h2.innerText.length > 1 ) {
				has_links = true;
				h2.setAttribute( 'id', kebabCase( h2.innerText ) );
				const li = document.createElement( 'li' );
				const a = document.createElement( 'a' );
				a.setAttribute( 'href', `#${kebabCase( h2.innerText )}` );
				a.innerText = h2.innerText;
				li.appendChild( a );
				ul.appendChild( li );
			}
		}
	} );

	if ( has_links ) {
		const heading = document.createElement( 'h2' );
		heading.innerText = 'Jump to';
		heading.className = 'jump-to__heading';
		jump_to_container.insertBefore( heading, ul );
	}

	function kebabCase( string: string ) {
		return string.replace( /([a-z])([A-Z])/g, '$1-$2' )
			.replace( /[\s_]+/g, '-' )
			.toLowerCase();
	}
} )();

( () => {
	// Copy URL buttons
	// eslint-disable-next-line  no-undef
	const copy_url_buttons = document.querySelectorAll( '.copy-url-button' ) as NodeListOf<HTMLButtonElement>; //

	copy_url_buttons.forEach( button => {
		const default_button_text = button.innerHTML;
		button.onclick = () => {
			navigator.clipboard.writeText( window.location.href );
			button.innerHTML = 'Link copied!';
			setTimeout( () => {
				button.innerHTML = default_button_text;
			}, 2000 );
		};
	} );
} )();

( () => {
	// Instantiate search
	new Search();
} )();

( () => {
	// Carousel
	// eslint-disable-next-line no-undef
	const embla_containers = document.querySelectorAll( '.carousel' ) as NodeListOf<HTMLElement>;
	if ( !embla_containers ) {
		return;
	}

	const options: EmblaOptionsType = {
		loop: true,
		containScroll: false,
		align: 'start',
	};

	embla_containers.forEach( embla_container => {
		const embla_viewport_node = <HTMLElement>embla_container.querySelector( '.carousel__viewport' );
		const prev_btn = <HTMLElement>embla_container.querySelector( '.carousel__control--prev' );
		const next_btn = <HTMLElement>embla_container.querySelector( '.carousel__control--next' );
		const slides = embla_container.querySelectorAll( '.carousel__slide' );
		let embla_api = null;

		if ( slides.length < 5 ) {
			embla_container.classList.add( 'carousel--no-init' );
			return;
		}

		embla_api = EmblaCarousel( embla_viewport_node, options );

		if ( prev_btn && next_btn ) {
			const remove_prev_next_btns_click_handlers = addPrevNextBtnsClickHandlers(
				embla_api,
				prev_btn,
				next_btn
			);
			embla_api
				.on( 'destroy', remove_prev_next_btns_click_handlers );
		}
	} );
} )();
